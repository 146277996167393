import * as React from 'react';
import classNames from 'classnames';
import Image from '../../../Image/viewer/Image';
import { ISliderGalleryItemProps } from '../SliderGalleryItem.types';
import ItemClickActionWrapper from '../../commons/viewer/ItemClickActionWrapper/ItemClickActionWrapper';

const getAspectRatioSizes = ({
  aspectRatio,
  keepImageAspectRatio,
  image,
}: ISliderGalleryItemProps): { width: number; height: number } => {
  if (keepImageAspectRatio) {
    return {
      width: image.width,
      height: image.height,
    };
  }

  const height = 10000;
  const width = aspectRatio * height;

  return {
    width,
    height,
  };
};

const noop = () => {};

const SliderGalleryItem: React.FC<ISliderGalleryItemProps> = props => {
  const {
    dataId,
    link,
    image,
    title,
    description,
    skinsStyle,
    extraClassNames,
    imageOnClickAction,
    openImageZoom = noop,
    itemClickHandler = noop,
    translate,
  } = props;

  const openImageZoomCallback = React.useCallback(() => openImageZoom(dataId), [
    dataId,
    openImageZoom,
  ]);

  const { width: sizerWidth, height: sizerHeight } = getAspectRatioSizes(props);

  return (
    <div
      className={classNames(skinsStyle.root, extraClassNames.root)}
      onClick={itemClickHandler}
    >
      <ItemClickActionWrapper
        imageOnClickAction={imageOnClickAction}
        openImageZoom={openImageZoomCallback}
        link={link}
        imgTitle={image.title || ''}
        imgAlt={image.alt}
        skinsStyle={skinsStyle}
        translate={translate}
      >
        <svg
          className={skinsStyle.sizer}
          viewBox={`0 0 ${sizerWidth} ${sizerHeight}`}
        />
        <div className={skinsStyle.imageWrapper}>
          <div className={skinsStyle.imageBorder}>
            <Image
              className={skinsStyle.image}
              id={`img_${image.containerId}`}
              {...image}
              title={title || description}
            />
          </div>
          <div className={skinsStyle.overlay} />
        </div>
      </ItemClickActionWrapper>
    </div>
  );
};

export default SliderGalleryItem;
